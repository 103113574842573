<template>
  <div class="open-platform">
    开发平台
  </div>
</template>

<script>
  export default {
    name: 'OpenPlatform'
  }
</script>

<style lang="scss" scoped>
  .open-platform {
    width: 1920px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-color: #666;
  }
</style>